* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px !important;
  padding: 0px !important;
}


.logo-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-logo img {
  width: 50px;
}

.dashboard .logo-detail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
    gap: 5px;
  }
  
  .dashboard .logo-detail-content h2 {
    font-family: "Inter";
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .dashboard .logo-detail-content p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #48536C;
  }
  
  .dashboard .dashboard-filter {
    margin-top: 40px;
  }
  
  .dashboard .dashboard-filter .date-filter {
    display: flex;
    justify-content: space-between;
  }
  
  .dashboard .dashboard-filter .date-logo {
    display: flex;
    align-items: center;
    gap: 5px;
      width: 100%;
    }
    
    .dashboard .dashboard-filter .date-logo h2 {
      font-family: "Inter";
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #272D3B;
}

.dashboard .dashboard-filter .custome-date {
  border-radius: 6px;
  border: none;
  font-size: 12px;
  font-family: "Inter";
  padding: 3px 10px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.dashboard .dashboard-filter .month-filter ul {
  margin-top: 20px;
  list-style: none;
  display: flex;
  gap: 10px;
}
.dashboard .dashboard-filter .month-filter ul li {
  padding: 6px 10px;
  border-radius: 10px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #48536C;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-bottom: 1px solid white;
}
.dashboard .dashboard-filter .month-filter ul li:hover {
  border-bottom: 1px solid red;
}

.dashboard .dashboard-filter .month-filter ul li:focus {
  background-color: red !important;
}
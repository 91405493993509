.dashboard {
    animation: slideFromright 0.9s forwards;
    transition: 500ms;
}

@keyframes slideFromright {
    0% {
        left: 200px;
        position: relative;
    }

    100% {

        left: 0;
    }
}

.sidebar {
    height: 100%;
    width: 85px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 15px;
    position: fixed;
    background: #F9FAFC;

}

.sidebar .sidebar-menu ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    height: 75vh;
    justify-content: center; */
    gap: 25px;
}

.sidebar .sidebar-inner {
    padding-top: 30px;
}
.slidermenuactive{
    filter: invert(25%) sepia(79%) saturate(3462%) hue-rotate(219deg) brightness(100%) contrast(102%);
}

.ant-popover .ant-popover-content .ant-popover-inner{
border-radius: 8px 0px 0px 8px;

box-shadow: 0px 4px 6px -2px #10182808;

box-shadow: 0px 12px 16px -4px #10182814;

}

@media only screen and (max-width: 576px) {

    /* .sidebar {
        height: 100vh;
        width: 40px;
    } */
    .sidebar .sidebar-inner img {
        width: 30px;
    }

    .mobile-reponsive .sidebar {
        flex-direction: row;
        padding: 0 32px;
        width: 100vw;
        height: 83px;
        position: relative;
        align-items: center
    }

    .mobile-reponsive .sidebar .sidebar-menu ul {
        flex-direction: row;
    }

    .mobile-reponsive .sidebar .sidebar-inner {
        padding-top: 0px;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {

    /* .sidebar {
        height: 100%;
        width: 40px;
    } */
    .sidebar .sidebar-inner img {
        width: 30px;
    }

    .mobile-reponsive .sidebar {
        flex-direction: row;
        padding: 0 32px;
        width: 100vw;
        height: 54px;
        position: relative;
        align-items: center
    }

    .mobile-reponsive .sidebar .sidebar-menu ul {
        flex-direction: row;
    }

    .mobile-reponsive .sidebar .sidebar-inner {
        padding-top: 0px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    .sidebar {
        height: 100%;
        width: 40px;
    }

    .sidebar .sidebar-inner img {
        width: 30px;
    }

    .mobile-reponsive .sidebar {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .mobile-reponsive .sidebar {
        display: none;
    }
}